.home_section {
    min-height: 300px;
    background-color: var(--color4);

}

.home_section img {
    border-radius: 40px;
}

.home_section p, .home_section h2 {
    width: 75%;
    margin: auto;
}

.home_section p {
    margin-top: 10px ;
}
