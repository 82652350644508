:root{
  --color1 : #00A632;
  --color2: #1F6935;
  --color3: #989898;
  --color4:black
}

body {
  background-color:var(--color4) !important;
  color: white;
}

p{
  font-family: Roboto, serif;
  color: white;
}

a {
  color: var(--color1) !important;
}

a:hover {
  color: white !important;
}

h2, h3, h4, h5, h6 {
  color: var(--color1) !important;
}

span {
  color: var(--color1);
}

.alert-primary {
  background-color: var(--color1) !important;
  border: 3px solid var(--color1) !important;
}

@media (max-width: 900px) {
  .three-js {
    display: none;
  }
}
