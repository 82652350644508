.hero_content {
    position: relative;
    z-index: 99;
    padding: 20px;
    border-radius: 10px;
    background-color: var(--color4) !important;
}

.hero_content span {
    color: var(--color1);
}

.hero_content .btn {
    border: 1px solid var(--color1);
    color: var(--color1);
}

.hero_content .btn:hover {
    background-color: var(--color1) ;
    border: 1px solid var(white);

    color: white;
}



@media (min-width: 900px) {
    .hero_content {
        position: absolute;
        top: 18%;
        right: 5%;
        width: 30%;
        padding: 20px;
        border-radius: 10px;
    }

    .hero_content>p{
        margin: 40px 0;

    }

}

@media (min-width: 1200px) {
    .hero_content {
        position: absolute;
        top: 30%;
        right: 5%;
        width: 30%;
        padding: 20px;
        border-radius: 10px;
    }

    .hero_content>p{
        margin: 40px 0;

    }

}
